<template>
  <div class="d-flex justify-content-center">
    <b-button
      v-for="{id, name} in catalogTypes"
      :key="id"
      :variant="isActive(id) ? 'secondary': 'secondary-light'"
      style="margin: 0 6px 0 6px"
      :disabled="!isActive(id) && isDisabled"
      @click="setActiveTab(id)"
    >{{ name }}</b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { catalogTypes } from '@/views/main/catalog/catalogType'

export default {
  name: 'CatalogCreateTabs',
  components: {
    BButton,
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  computed: {
    catalogForm() {
      return this.$store.state[this.MODULE_NAME].catalogForm
    },
    isDisabled() {
      return Boolean(this.catalogForm.id)
    },
  },
  methods: {
    setActiveTab(id) {
      if (!this.isDisabled) this.catalogForm.type_id = id
    },
    isActive(type) {
      if (this.isDisabled) {
        return this.catalogForm.type_id.id === type
      }
      return this.catalogForm.type_id === type
    },
  },
  setup() {
    const MODULE_NAME = 'catalogs'

    return {
      MODULE_NAME,
      catalogTypes,
    }
  },
}
</script>
<style lang="scss">
.opacity {
  opacity: 0.5;
}
</style>
