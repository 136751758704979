<template>
  <div>
    <form-features-wrapper
      :is-update-form="isUpdateForm"
      :can-edit="ACCESS_ABILITY_FOR_CATALOG"
      :is-pen-button="organizationRequiresEditConfirmation"
      :is-view-trash-button="false"
      :is-editable="isEditable"
      @changeEditable="changeEditable"
      @trashButtonHandler="trashButtonHandler"
    >
      <error-alert
        :fields="formFields"
        :error="error"
      />
      <b-form>
        <validation-observer
          ref="catalogFormVal"
        >
          <b-row>
            <b-col md="6">
              <component
                :is="formFields['sku'].type"
                v-if="hasKey('sku')"
                :ref="`${'sku'}_ref`"
                v-model="catalog['sku']"
                v-bind="getProps('sku')"
                class="position-relative catalog-input-component"
                :is-editable="isEditable"
                @blur="generateSku"
              >
                <template #input-extra-el>
                  <span
                    v-if="generateSkuLoading"
                    class="position-absolute"
                    style="right: 10px; bottom: 12px"
                  >
                    <b-spinner
                      small
                      variant="secondary"
                      type="grow"
                    />
                  </span>
                </template>
              </component>
              <component
                :is="formFields[field].type"
                v-for="field in getFilterKeys(['name','model'])"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalog[field]"
                v-bind="getProps(field)"
                class="catalog-input-component"
              />
              <div v-if="hasKey('categories')">
                <component
                  :is="formFields['categories'].type"
                  ref="categories_ref"
                  v-model="catalog.categories"
                  v-bind="getProps('categories')"
                  :selected-values="returnTags('categories')"
                />
              </div>

              <div
                class="d-flex align-items-center"
                style="gap: 8px;"
                @keydown.enter="showAddBrandModalEnter()"
              >
                <component
                  :is="formFields[field].type"
                  v-for="field in getFilterKeys(['brand_id'])"
                  :key="field"
                  :ref="`${field}_ref`"
                  v-model="catalog[field]"
                  v-bind="getProps(field)"
                  class="catalog-input-component h-auto w-100"
                />
                <button-dashed
                  v-if="isUpdate ? isUpdate && getStockItem: true "
                  class="btnBorderRevert position-relative"
                  style="top: 5px"
                >
                  <feather-icon
                    icon="LAddButtonIcon"
                    class="cursor-pointer"
                    size="36"
                    @click="showAddBrandModal()"
                  />
                </button-dashed>

                <!-- <OrderPlacedByModal ref="modalOrderPlacedBy" @ok="handleModalOk" /> -->
              </div>
              <component
                :is="formFields[field].type"
                v-for="field in getFilterKeys(['mpn'])"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalog[field]"
                v-bind="getProps(field)"
                class="catalog-input-component h-auto"
              />

              <!--              <component-->
              <!--                :is="formFields[field].type"-->
              <!--                v-for="field in getFilterKeys([-->
              <!--                  'supplier_sku',-->
              <!--                ])"-->
              <!--                :key="field"-->
              <!--                :ref="`${field}_ref`"-->
              <!--                v-model="catalog[field]"-->
              <!--                v-bind="getProps(field)"-->
              <!--                class="catalog-input-component h-auto"-->
              <!--              />-->
              <div class="pb-1">
                <component
                  :is="formFields[field].type"
                  v-for="field in getFilterKeys(['replacement_cost'])"
                  :key="field"
                  :ref="`${field}_ref`"
                  v-model="catalog[field]"
                  v-bind="getProps(field)"
                  style="margin-bottom: -1px"
                  class="catalog-input-component h-auto"
                />
                <span
                  v-if="isSubmit && catalog['replacement_cost'] == null && hasKey('replacement_cost')"
                  class="text-danger"
                >{{ $t('The Replacement Cost field is required') }}</span>
              </div>
              <component
                :is="formFields[field].type"
                v-for="field in getFilterKeys([
                  'msrp',
                  'comments',
                ])"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalog[field]"
                v-bind="getProps(field)"
                class="catalog-input-component h-auto"
              />
              <label class="font-weight-bolder">{{ $t('Status') }}</label>
              <component
                :is="formFields[field].type"
                v-for="field in getFilterKeys([
                  'is_active',
                  'non_searchable',
                ])"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalog[field]"
                v-bind="getProps(field)"
                class="catalog-input-component h-auto"
              />
            </b-col>
            <b-col md="6">
              <component
                :is="formFields['tags'].type"
                v-if="hasKey('tags')"
                ref="tags_ref"
                v-model="catalog.tags"
                v-bind="getProps('tags')"
                :selected-values="returnTags('tags')"
                class="catalog-input-component"
              />
              <component
                :is="formFields['notes'].type"
                v-if="hasKey('notes')"
                :ref="`${'notes'}_ref`"
                v-model="catalog['notes']"
                v-bind="getProps('notes')"
                @input="changeHandler"
              />
              <component
                :is="formFields[field].type"
                v-for="field in getFilterKeys(['allow_discount'])"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalog[field]"
                v-bind="getProps(field)"
                class="catalog-input-component"
              />
              <div
                v-if="getFilterKeys(assetRuleFields).length > 0"
                class="mb-2"
              >
                <label class="font-weight-bolder">{{ $t('Assets\' Rules') }}</label>
                <component
                  :is="formFields[field].type"
                  v-for="field in getFilterKeys(needsIndividualAssetFields)"
                  :key="field"
                  :ref="`${field}_ref`"
                  v-model="catalog[field]"
                  v-bind="getProps(field)"
                  :is-editable="isHasInventoryItems ? isHasInventoryItems : isEditable"
                  @change="handleIndividualAsset"
                />
                <component
                  :is="formFields[field].type"
                  v-for="field in ['requires_individual_asset_price',]"
                  :key="field"
                  :ref="`${field}_ref`"
                  v-model="catalog[field]"
                  v-bind="getProps(field)"
                  :is-editable="isHasInventoryItems ? isHasInventoryItems : !isNeedsIndividual"
                />
                <component
                  :is="formFields[field].type"
                  v-for="field in getFilterKeys(assetRuleFields)"
                  :key="field"
                  :ref="`${field}_ref`"
                  v-model="catalog[field]"
                  v-bind="getProps(field)"
                />
              </div>
              <div
                v-if="getFilterKeys(fullfilmentRules).length > 0"
                class="mb-2"
              >
                <label class="font-weight-bolder">{{ $t('Fulfillment Rules') }}</label>
                <component
                  :is="formFields[field].type"
                  v-for="field in getFilterKeys(fullfilmentRules)"
                  :key="field"
                  :ref="`${field}_ref`"
                  v-model="catalog[field]"
                  v-bind="getProps(field)"
                />
              </div>

              <component
                :is="formFields[field].type"
                v-for="field in getFilterKeys(['turnaround_time', 'product_unit_type_id'])"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalog[field]"
                v-bind="getProps(field)"
                class="catalog-input-component"
              />
            </b-col>
          </b-row>
          <h4
            v-if="hasKey('pick_time_per_item')"
            class="font-weight-bolder text-body"
          >
            {{ $t('Preparation times') }}
          </h4>
          <b-row>
            <b-col md="6">
              <component
                :is="formFields[field].type"
                v-for="field in getFilterKeys(['pick_time_per_item','pack_time_per_item'])"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalog[field]"
                v-bind="getProps(field)"
                class="catalog-input-component"
              />
            </b-col>
            <b-col md="6">
              <component
                :is="formFields[field].type"
                v-for="field in getFilterKeys(['receive_time_per_item', 'put_back_time_per_item'])"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalog[field]"
                v-bind="getProps(field)"
                class="catalog-input-component"
              />
            </b-col>
          </b-row>
          <b-row v-if="isUpdateForm && catalog.type_id.id < CATALOG_TYPE_NON_STOCK">
            <b-col md="6">
              <component
                :is="formFields[field].type"
                v-for="field in getFilterKeys([
                  'type_id',
                ])"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalog[field]"
                v-bind="getProps(field)"
                class="catalog-input-component h-auto"
              />
            </b-col>
          </b-row>
          <portal to="body-footer">
            <div class="d-flex mt-2 pb-1 justify-content-between">
              <div>
                <b-button
                  v-if="!isFormChanged"
                  class="cancelBtn font-medium-1 font-weight-bolder"
                  variant="outline-primary"
                  @click="$router.push({name: 'home-main-catalog-list-products'})"
                >
                  {{ $t('Back to List') }}
                </b-button>
                <b-button
                  v-else
                  class="cancelBtn font-medium-1 font-weight-bolder"
                  variant="outline-primary"
                  @click="loader"
                >
                  <feather-icon
                    icon="LCancelIcon"
                    size="16"
                  />
                  {{ $t('Cancel') }}
                </b-button>
              </div>
              <div class="d-flex">
                <b-button
                  v-if="isUpdate"
                  class="mr-1 d-flex"
                  @click="printBarcode"
                >
                  <feather-icon
                    class="mr-1"
                    icon="LPrintIcon"
                    size="16"
                  />
                  <Printer>
                    <div
                      id="barcode"
                      hidden
                    >
                      <div v-html="catalogProductBarcode" />
                    </div>
                  </Printer>
                  <span>{{ $t('Print Barcode') }}</span>
                </b-button>
                <b-button
                  v-if="isUpdate"
                  :disabled="!isUpdate"
                  class="mr-1 d-flex"
                  @click="printFull"
                >
                  <feather-icon
                    class="mr-1"
                    icon="LPrintIcon"
                    size="16"
                  />
                  <!--                  <Printer>-->
                  <!--                    <div-->
                  <!--                      id="page"-->
                  <!--                      hidden-->
                  <!--                    >-->
                  <!--                      <div v-html="getPrintFull()" />-->
                  <!--                    </div>-->
                  <!--                  </Printer>-->
                  <span>{{ $t('Print Full Spec Sheet') }}</span>
                </b-button>
                <b-button
                  v-if="$can(ACCESS_ABILITY_FOR_CATALOG.action, ACCESS_ABILITY_FOR_CATALOG.subject)"
                  variant="success"
                  class="saveBtn font-medium-1 font-weight-bolder"
                  :disabled="(!isFormChanged || isLoading)"
                  @click="submit"
                >
                  <span v-if="!isLoading">
                    {{ $t('SAVE') }}
                  </span>
                  <b-spinner
                    v-if="isLoading"
                    small
                    label="Small Spinner"
                  />
                </b-button>
              </div>
            </div>
          </portal>
        </validation-observer>
      </b-form>
      <b-s-warning-modal ref="confirm-modal" />
      <!--New brand add modal-->
      <b-modal
        v-if="isUpdate ? isUpdate && getStockItem: true "
        :id="ADD_BRAND_MODAL"
        centered
        hide-header
        body-class="customer-csv-file-export-modal-body py-2"
        footer-class="customer-csv-file-export-modal-footer"
        ok-variant="success"
        ok-title="OK"
        modal-class="customer-csv-file-export"
        @ok="submitAddBrand"
        @cancel="cancelAddBrand"
      >
        <h4 class="text-center mb-2">
          {{ $t('Would you like to add a new brand') }}
        </h4>
        <b-row>
          <b-col
            md="12"
          >
            <component
              :is="formFields[field].type"
              v-for="field in getFilterKeys(['brand_name'])"
              :key="field"
              v-model="brand[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
              @keyup.enter="submitAddBrand"
            />
            <component
              :is="formFields[field].type"
              v-for="field in getFilterKeys(['brand_is_active'])"
              :key="field"
              v-model="brand[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
      </b-modal>
      <!--New brand add modal-->
    </form-features-wrapper>
  </div>
</template>

<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, BSpinner,
} from 'bootstrap-vue'
import LTagsInput from '@/views/components/DynamicForm/components/LTagsInput/LTagsInput.vue'
import { scrollToError } from '@core/utils/utils'
import Printer from '@/views/components/Printer/Printer.vue'
import WysiwygEditor from '@/views/components/DynamicForm/components/WysiwygEditor.vue'
import { CATALOG_TYPE_NON_STOCK, catalogTypes, getCatalogType } from '@/views/main/catalog/catalogType'
import store from '@/store'
import html2canvas from 'html2canvas'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'

import router from '../../../../router'

export default {
  name: 'CatalogForm',
  provide: {
    brandModuleName: 'brand',
  },
  components: {
    ButtonDashed,
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    Printer,
    WysiwygEditor,
    LTagsInput,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      required,
      generateSkuLoading: false,
      isSubmit: false,
      isLoading: false,
      isEditable: !this.isUpdateForm ? false : store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    isHasInventoryItems() {
      return this.$store.state[this.MODULE_NAME].catalog.is_has_inventory_items
    },
    catalog() {
      return this.$store.state[this.MODULE_NAME].catalogForm
    },
    brandList() {
      return this.$store.state.listModule.brandsList
    },
    isNeedsIndividual() {
      return this.$store.state[this.MODULE_NAME].catalogForm.needs_individual_asset_id
    },
    catalogProductBarcode() {
      return this.$store.state[this.MODULE_NAME].catalogProductBarcode
    },
    getCatalog() {
      return this.$store.state[this.MODULE_NAME].catalog
    },
    getStockItem() {
      return this.$store.state[this.MODULE_NAME].catalog?.type?.id === 1
    },
    catalogFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].catalogFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.catalog) !== this.catalogFormClone
    },
    isUpdate() {
      return Boolean(this.catalog.id)
    },
    brand() {
      return this.$store.state[this.MODULE_NAME].brandForm
    },

  },
  mounted() {
    this.generateSku()
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CATALOG_FORM_CLONE`, this.catalog)
  },
  methods: {
    toString,
    handleIndividualAsset(value) {
      if (!value) {
        this.catalog.requires_individual_asset_price = value
      }
    },
    router() {
      return router
    },
    changeEditable() {
      this.isEditable = !this.isEditable
    },
    showAddBrandModal() {
      this.$bvModal.show(this.ADD_BRAND_MODAL)
    },
    showAddBrandModalEnter() {
      if (this.brandList.length === 0) {
        this.$bvModal.show(this.ADD_BRAND_MODAL)
      }
    },
    trashButtonHandler() {
      // const { id } = this.getCatalog
      // this.confirmNotification(this, { id }, `${this.MODULE_NAME}/del`)
      //   .then(
      //     () => {
      //       this.$router.push({ name: 'home-main-catalog-list' })
      //     },
      //   )
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.isSubmit)
    },
    hasKey(key) {
      // eslint-disable-next-line no-prototype-builtins
      return this.formFields.hasOwnProperty(key)
    },
    cancelAddBrand() {
      this.$store.commit(`${this.MODULE_NAME}/ADD_BRAND`, {})
    },
    getFilterKeys(keys) {
      return keys.filter(key => (this.hasKey(key)))
    },
    returnTags(tagsArrName) {
      return this.getCatalog[tagsArrName]?.map(tag => tag?.label)
    },
    changeHandler(event) {
      this.catalog.notes = event
    },
    submitAddBrand() {
      this.error = {}
      const { brand_name, brand_is_active } = this.brand
      const data = {
        name: brand_name,
        is_active: brand_is_active,
      }
      if (brand_name.length) {
        this.sendNotification(this, data, `${this.MODULE_NAME}/brandAdd`)
          .then(() => {
            this.cancelAddBrand()
          }).catch(err => {
            this.error = err.response?.data
          })
      }
    },
    submit() {
      this.error = {}
      this.isSubmit = true

      // Checking replacement_cost to feet custom error message
      function isNotNullOrEmpty(value) {
        if (this.hasKey('replacement_cost')) {
          return (
            value !== null && value !== undefined && value !== ''
          )
        }
        return true
      }

      const boundIsNotNullOrEmpty = isNotNullOrEmpty.bind(this)

      this.$refs.catalogFormVal.validate()
        .then(success => {
          if (success && boundIsNotNullOrEmpty(this.catalog.replacement_cost)) {
            this.isLoading = true
            const catalog = this.service.afterMappingFields(this.catalog)
            catalog.detail = this.service.afterMappingDetailFields(this.catalog)
            this.sendNotification(this, catalog, `${this.MODULE_NAME}/${this.catalog.id ? 'update' : 'create'}`)
              .then(res => {
                this.clear()
                if (!this.catalog.id) {
                  this.$router.push({
                    name: 'home-catalog-rates',
                    params: { id: res.data.data?.id },
                  })
                }
                this.$emit('refetchData')
              })
              .catch(err => {
                // this.error = err.response?.data
                // this.error = true
                this.errorNotification(this, err)
              }).finally(() => {
                this.isLoading = false
              })
          } else {
            scrollToError(this, this.$refs.catalogFormVal)
          }
        })
    },
    clear() {
      this.$refs.catalogFormVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_CATALOG_FORM`, {
        sku: '',
      })
      this.$store.commit(`${this.MODULE_NAME}/RESET_CATALOG`)
    },
    cancel() {
      this.clear()
      this.$router.push({ name: 'home-main-catalog-list' })
    },
    loader() {
      this.$refs.catalogFormVal.reset()
      const smth = JSON.parse(this.catalogFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_CATALOG_FORM`, smth)
    },
    getProps(fieldName) {
      return {
        is: this.formFields[fieldName].type,
        field: this.formFields[fieldName],
        name: fieldName,
        isEditable: this.isEditable,
      }
    },
    generateSku() {
      if (this.catalog.sku) return
      this.error = {}
      this.generateSkuLoading = true
      // eslint-disable-next-line no-return-assign
      this.$store.dispatch(`${this.MODULE_NAME}/getSku`, {}).then(res => {
        this.catalog.sku = res.data.data.sku
        return res.data.data.sku
      })
        .catch(err => {
          this.error = err.response?.data
          // eslint-disable-next-line no-return-assign
        }).finally(() => this.generateSkuLoading = false)
    },
    printBarcode() {
      this.$htmlToPaper('barcode')
    },
    printFull() {
      const appContentEl = document.getElementById('mainBody')

      html2canvas(appContentEl).then(canvas => {
        const printWindow = window.open('', '_blank')
        const printDocument = printWindow.document

        printDocument.write(`
        <html>
          <head>
            <title>Print Canvas</title>
            <style>
              body { margin: 0;width: 100%;height: 100%; }
              canvas { display: block; width: 100%;height: 100%;}
            </style>
          </head>
          <body>
            <canvas id="printCanvas" style="display:block;"></canvas>
          </body>
        </html>
      `)

        const printCanvas = printDocument.getElementById('printCanvas')
        printCanvas.width = canvas.width
        printCanvas.height = canvas.height

        const printCtx = printCanvas.getContext('2d')
        printCtx.drawImage(canvas, 0, 0)

        printWindow.print()
        printWindow.close()
      })
      // this.$htmlToPaper('page')
    },
    getPrintFull() {

    },
    getBackToListPath() {
      console.log('this.$route.query', this.$route.query)
      return getCatalogType(this.catalog?.type_id?.id ? this.catalog?.type_id?.id : this.catalog?.type_id).path
    },
  },
  setup(props) {
    const { service } = props
    const MODULE_NAME = 'catalogs'
    const MODULE_NAME_BRAND = 'catalogs'
    const MODULE_NAME_CLONE = 'cloneData'
    const formFields = service.getAllFormFields()
    const disabledFields = service.getDisabledFieldsOnUpdate()
    const ACCESS_ABILITY_FOR_CATALOG = service.ACCESS_ABILITY_CATALOG
    const { catalogFormFields, catalogDetailsFormFields } = service
    const assetRuleFields = ['can_be_procured', 'can_be_rented_individually', 'can_be_sub_rented', 'can_be_sold']
    const needsIndividualAssetFields = ['needs_individual_asset_id']
    const fullfilmentRules = ['requires_container', 'can_be_transferred']
    const ADD_BRAND_MODAL = 'add_brand_modal'

    return {
      MODULE_NAME_BRAND,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      catalogFormFields,
      ADD_BRAND_MODAL,
      catalogDetailsFormFields,
      formFields,
      disabledFields,
      assetRuleFields,
      fullfilmentRules,
      needsIndividualAssetFields,
      catalogTypes,
      CATALOG_TYPE_NON_STOCK,
      getCatalogType,
      ACCESS_ABILITY_FOR_CATALOG,
    }
  },
}
</script>
