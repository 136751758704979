<template>
  <b-row>
    <b-col md="6">
      <div
        v-for="field in ['sku', 'name', 'model', 'categories', 'brand_id', 'mpn', 'replacement_cost', 'msrp', 'is_active']"
        :key="field"
        style="height: 70px"
      >
        <span class="inputLabel">{{ formFields[field].label === 'Active' ? 'Status' : formFields[field].label }}</span>
        <b-skeleton
          height="40px"
          style="border-radius: 5px"
        />
      </div>
    </b-col>
    <b-col md="6">
      <div
        v-for="field in ['tags']"
        :key="field"
        style="height: 70px"
      >
        <span class="inputLabel">{{ formFields[field].label }}</span>
        <b-skeleton
          height="40px"
          style="border-radius: 5px"
        />
      </div>
      <div
        class="mb-3"
        style="height: 250px"
      >
        <span class="inputLabel">{{ formFields['notes'].label }}</span>
        <div
          class="border d-flex flex-column"
          style="padding: 15px; border-radius: 5px"
        >
          <b-skeleton
            height="18px"
            width="50%"
            style="border-radius: 5px"
          />
          <b-skeleton
            height="18px"
            width="90%"
            style="border-radius: 5px"
          />
          <b-skeleton
            width="80%"
            height="18px"
            style="border-radius: 5px"
          />
          <b-skeleton
            width="85%"
            height="18px"
            style="border-radius: 5px"
          />
          <b-skeleton
            width="60%"
            height="18px"
            style="border-radius: 5px"
          />
          <b-skeleton
            height="18px"
            style="border-radius: 5px"
          />
          <b-skeleton
            height="18px"
            style="border-radius: 5px"
          />
        </div>
      </div>
      <label class="font-weight-bolder">{{ $t('Assets\' Rules') }}</label>
      <div
        v-for="field in ['needs_individual_asset_id', 'requires_individual_asset_price', 'can_be_procured', 'can_be_rented_individually', 'can_be_sub_rented', 'can_be_sold']"
        :key="field"
        class="d-flex align-items-center justify-content-between ml-1"
        style="margin-top: 8px"
      >
        <span class="">{{ formFields[field].label }}</span>
        <b-skeleton
          class="mb-0"
          height="18px"
          style="border-radius: 5px; margin-left: 4px; min-width: 18px !important;"
        />
      </div>
      <div class="mt-1" />
      <label class="font-weight-bolder">{{ $t('Fulfillment Rules') }}</label>
      <div
        v-for="field in ['requires_container', 'can_be_transferred']"
        :key="field"
        class="d-flex align-items-center justify-content-between ml-1"
        style="margin-top: 8px"
      >
        <span class="">{{ formFields[field].label }}</span>
        <b-skeleton
          class="mb-0"
          height="18px"
          style="border-radius: 5px; margin-left: 4px; min-width: 18px !important;"
        />
      </div>
      <div
        v-for="field in ['turnaround_time']"
        :key="field"
        style="height: 70px"
        class="mt-2"
      >
        <span class="inputLabel">{{ formFields[field].label }}</span>
        <b-skeleton
          height="40px"
          style="border-radius: 5px"
        />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'
import BaseCatalogService from '@/views/main/catalog/service/BaseCatalogService'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
    const baseCatalogService = new BaseCatalogService()

    const formFields = baseCatalogService.getAllFormFields()
    return {
      formFields,
    }
  },
}
</script>
