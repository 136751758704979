import BaseCatalogService from '@/views/main/catalog/service/BaseCatalogService'
import {
  CHECKBOX_LEF,
  L_ACTIVE_CHECK_LF_BOX,
  L_TAGS_INPUT,
  SELECT_INPUT,
  TEXT_INPUT,
  TEXTAREA_INPUT,
  WYSIWYG_EDITOR,
} from '@/views/components/DynamicForm/constants'
import mappingFields from '@/libs/mappingFields'
import { isUndefined } from '@core/components/b-table-responsive/table-responsive/table/utils/inspect'

export default class NonStockService extends BaseCatalogService {
    catalogFormFields = {
      sku: {
        type: TEXT_INPUT,
        label: 'SKU',
        placeholder: 'Type or leave blank for autogeneration...',
      },
      name: {
        type: TEXT_INPUT,
        label: 'Name',
        rules: 'required',
        placeholder: 'Type...',
      },

      type_id: {
        type: SELECT_INPUT,
        label: 'Type',
        store: 'productTypesList',
        rules: 'required',
        options: {
          label: 'name',
        },
      },
      categories: {
        type: L_TAGS_INPUT,
        label: 'Category',
        rules: 'required',
        store: 'categoriesList',
      },
      tags: {
        type: L_TAGS_INPUT,
        label: 'Tags',
        store: 'tagsList',
      },
      product_unit_type_id: {
        type: SELECT_INPUT,
        label: 'Unit Type',
        store: 'unitTypeList',
        rules: 'required',
      },
      is_active: {
        type: CHECKBOX_LEF,
        label: 'Active',
        rules: 'required',
      },
    }

    catalogDetailsFormFields = {
      notes: {
        type: WYSIWYG_EDITOR,
        label: 'Description',
      },
      comments: {
        type: TEXTAREA_INPUT,
        label: 'Comments',
        options: {
          placeholder: 'Write notes here...',
        },
      },
    }

    beforeMappingFields(data) {
      return mappingFields(Object.keys(this.catalogFormFields), data, {
        id: data.id,
        // type_id: data.type?.id,
        type_id: data.type,
        product_unit_type_id: data.product_unit_type,
        categories: data.categories ? data.categories.map(category => category.label) : [],
        tags: data.tags ? data.tags.map(tag => tag.label) : [],
      })
    }

    afterMappingFields(data) {
      return mappingFields(Object.keys(this.catalogFormFields), data, {
        id: data.id,
        product_unit_type_id: data.product_unit_type_id?.id,
        type_id: isUndefined(data.id) ? data.type_id : data.type_id.id,

      })
    }
}
